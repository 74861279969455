import { environment } from 'config/environment'

export const featureFlags = {
  eSignatureFlow: environment.isDevelopment || environment.isStaging,
  assetVaults: true,
  capitalCalls: environment.isStaging || environment.isDevelopment,
  createLoanFlow: true,
  kycFlow: environment.isStaging || environment.isDevelopment,
  socialLogin: environment.isStaging || environment.isDevelopment,
  editOfferingFlow: true,
  reportingPage: true,
  startOfferingFlow: true,
  emailsQueueCronJob: environment.isStaging || environment.isDevelopment,
  apyOverride: true,
  disableOnboardingAPI: true,
  queryDecentralizedGraph: true,
}

export type Feature = keyof typeof featureFlags
